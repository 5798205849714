<!--
 * @Author: wangwei
 * @Date: 2020-12-28 16:06:28
 * @LastEditTime: 2022-02-23 11:04:23
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /java-pc/src/views/member/order/List.vue
-->
<template>
  <div class="sld_order_list">
    <MemberTitle :memberTitle="L['我的订单']"></MemberTitle>
    <div class="container">
      <h3>{{ L["我的订单"] }}</h3>
      <div class="sld_order_nav">
        <div class="sld_order_nav_con flex_row_start_center pointer">
          <div
            :class="{ item: true, active: current_state === '' }"
            @click="changeState('')"
          >
            {{ L["全部订单"] }}
          </div>
          <div
            :class="{ item: true, active: current_state == 10 }"
            @click="changeState(10)"
          >
            待签约
          </div>
          <div
            :class="{ item: true, active: current_state == 20 }"
            @click="changeState(20)"
          >
            执行中
          </div>
          <div
            :class="{ item: true, active: current_state == 30&&evaluate_state != 1 }"
            @click="changeState(30)"
          >
            已完成
          </div>
          <div
            :class="{
              item: true,
              active: current_state === 0 ,
            }"
            @click="changeState(0)"
          >
            已取消
          </div>
          <div
            :class="{
              item: true,
              active: current_state == 30 && evaluate_state == 1,
            }"
            @click="changeState(30, 1)"
          >
            {{ L["待评价"] }}
          </div>
        </div>
        <div class="search_con flex_row_start_center">
          <div class="search_incon flex_row_start_center">
            <el-input
              class="search_input"
              v-model="orderSn"
              :placeholder="L['商品名称/订单编号']"
              clearable
              @clear="clear"
            >
            </el-input>
            <div class="search pointer" @click="getOrderList">
              {{ L["搜索订单"] }}
            </div>
          </div>
        </div>
      </div>
      <div class="order_title_info flex_row_start_center">
        <div class="time_select"></div>
        <div class="good_price">{{ L["单价"] }}</div>
        <div class="order_price">{{ L["订单金额"] }}</div>
        <div class="num">{{ L["数量"] }}</div>
        <div class="state">{{ L["状态"] }}</div>
        <div class="process">流程</div>
        <div class="processNode">流程节点数</div>
        <div class="nodeState">子节点状态</div>
        <div class="oprates">{{ L["操作"] }}</div>
      </div>
      <div
        class="order_item"
        v-for="(orderItem, index) in order_list.data"
        :key="index"
      >
        <div class="title flex_row_start_center">
          <div>{{ L["下单时间"] }}：{{ orderItem.createTime }}</div>
          <div class="order_num">
            {{ L["订单号"] }}: {{ orderItem.orderSn }}
          </div>
          <div class="store_name" @click="toStore(orderItem.storeId)">
            {{ orderItem.storeName }}
          </div>
          <div class="store_service" @click="toKefu(orderItem)">
            <img src="@/assets/goods/server.png" alt="" />
          </div>
        </div>
        <div class="good_info_con flex_row_start_center">
          <div class="good_info flex_column_center_start">
            <div
              class="item flex_row_start_center"
              v-for="(goodItem, index) in orderItem.orderProductListVOList"
              :key="index"
            >
              <div class="good flex_row_start_center">
                <img
                  @click="goGoodsDetail(goodItem.productId)"
                  :src="goodItem.productImage"
                  alt
                />
                <div class="right">
                  <div
                    class="good_name overflow_ellipsis_two"
                    @click="goGoodsDetail(goodItem.productId)"
                  >
                    {{ goodItem.goodsName }}
                  </div>
                  <!-- <div class="spec overflow_ellipsis">
                    {{ goodItem.specValues }}
                  </div> -->
                </div>
              </div>
              <div class="good_price">￥{{ goodItem.productShowPrice }}</div>
            </div>
          </div>
          <!-- <div class="after flex_row_center_center">--</div> -->
          <div class="order_price flex_row_center_center">
            ￥{{ orderItem.totalMoney }}
          </div>
          <div class="num flex_column_center_center">*{{ orderItem.goodsNum }}</div>
          <div class="state flex_column_center_center">
            <span class="state_value">{{ orderItem.orderStateValue }}</span>
          </div>
          <!-- 流程 -->
          <div class="processDe flex_column_center_center">
            <span class="state_value">{{ orderItem.templateName }}</span>
          </div>
          <!-- 流程节点 -->
          <div class="processNodeDe flex_column_center_center">
            <span class="state_value">{{ orderItem.nodeNum }}</span>
          </div>
          <!-- 子节点状态 -->
          <div class="nodeStateDe flex_column_center_center">
            <span class="state_value">{{ orderItem.nodeName }}</span>
          </div>
          <div class="oprate flex_column_center_center">
            <span class="detail" @click="goDetail(orderItem.orderSn)">{{
              L["订单详情"]
            }}</span>
            <span
              class="detail"
              v-if="orderItem.orderState == 20"
              @click="goAriDetail(orderItem.articleIds)"
            >
              查看文章
            </span>
            <!-- 待评价订单可以评价 -->
            <div
              v-if="orderItem.orderState == 30 && orderItem.evaluateState != 3"
              class="action-btn recom flex_row_center_center"
              @click="remainEvaluated(orderItem.orderSn)"
            >
              {{ L["评价"] }}
            </div>
            <!-- 待付款订单可以取消订单 -->
            <div
              v-if="orderItem.orderState == 10"
              class="action-btn flex_row_center_center"
              @click="showSelectReasonDialog(orderItem.parentSn)"
            >
              {{ L["取消订单"] }}
            </div>
            <div
              v-if="orderItem.orderState == 10"
              class="action-btn flex_row_center_center"
              @click="openhetong(orderItem)"
            >
              查看合同
            </div>
            <!-- 待付款订单可以立即支付 -->

            <!-- 已取消、全部评价完成订单可以删除订单 -->
            <div
              v-if="
                orderItem.orderState == 0 ||
                  (orderItem.orderState == 40 && orderItem.evaluateState == 3)
              "
              class="action-btn flex_row_center_center"
              @click="delOrder(orderItem.orderSn)"
            >
              {{ L["删除订单"] }}
            </div>
          </div>
        </div>
      </div>
      <el-pagination
        @prev-click="handlePrevCilickChange"
        @next-click="handleNextCilickChange"
        @current-change="handleCurrentChange"
        :currentPage="pageData.current"
        :page-size="pageData.pageSize"
        layout="prev, pager, next, jumper"
        :total="pageData.total"
        :hide-on-single-page="true"
        class="flex_row_end_center"
      ></el-pagination>
      <SldCommonEmpty
        v-if="order_list.data.length == 0"
        :tip="L['暂无订单～']"
        totalWidth="934px"
      />
    </div>
    <!-- 取消理由弹窗 start -->
    <el-dialog
      :title="L['取消订单理由']"
      v-model="cancel_order"
      customClass="select_reason_width"
      :before-close="(current_reason_id = '')"
      lock-sroll="false"
    >
      <div class="cancel_list_con">
        <div
          v-for="(reasonItem, index) in cancel_list.data"
          :key="index"
          :class="{
            reason_item: true,
            flex_row_between_center: true,
            active: current_reason_id == reasonItem.reasonId,
          }"
          @click="selectReason(reasonItem.reasonId)"
        >
          <span class="reason_text">{{ reasonItem.content }}</span>
          <img
            :src="
              current_reason_id == reasonItem.reasonId ? selected : not_select
            "
            alt
          />
        </div>
      </div>
      <div class="confirm_cancel_btn" @click="confirmCancel()">
        {{ L["确定"] }}
      </div>
    </el-dialog>
    <!--  取消地址理由弹窗 end  -->
    <!-- 查看物流弹窗 start -->
    <el-dialog
      :title="L['物流信息']"
      v-model="show_logistics"
      customClass="select_reason_width"
      :before-close="handleClose"
      lock-sroll="false"
    >
      <div class="logistics_info">
        <p>
          {{ logistics_info.data.type == 1 ? L["联系电话"] : L["承运人"] }}：{{
            logistics_info.data.expressName
          }}
        </p>
        <p>
          {{ logistics_info.data.type == 1 ? L["联系人"] : L["运单号"] }}：{{
            logistics_info.data.expressNumber
          }}
        </p>
      </div>
      <div class="logistics_list_con">
        <el-timeline>
          <el-timeline-item
            v-for="(logistics, index) in logistics_info.data.routeList"
            :key="index"
            :timestamp="logistics.acceptStation"
            >{{ logistics.acceptTime }}</el-timeline-item
          >
        </el-timeline>
      </div>
    </el-dialog>
    <!--  查看物流弹窗 end  -->
    <!-- 地址选择弹窗 start -->
    <el-dialog
      :title="L['更换地址']"
      v-model="show_select_address"
      customClass="select_address_width"
      :before-close="handleClose"
      lock-sroll="false"
    >
      <div class="out_stock_dialog address_con">
        <div
          v-for="(item, index) in address_list.data"
          :key="index"
          @click="selectAddress(index)"
          :class="{
            address_item: true,
            flex_column_start_start: true,
            select: current_address_index == index,
          }"
        >
          <span>{{ L["收货人"] }}：{{ item.memberName }}</span>
          <span>{{ L["联系方式"] }}：{{ item.telMobile }}</span>
          <span class="overflow_ellipsis address_text" title="sss"
            >{{ L["收货地址"] }}：{{ item.addressAll }}</span
          >
          <img
            v-if="current_address_index == index"
            class="selected"
            :src="invoice_selected"
            alt
          />
        </div>
      </div>
      <div class="btn_con flex_row_center_center">
        <div class="confirm_cancel_btn" @click="confirmChangeAddress()">
          {{ L["提交修改"] }}
        </div>
      </div>
    </el-dialog>
    <!-- 地址选择弹窗 end -->
    <!-- 查看附件弹窗 start-->
    <div class="order_list_el_diaLog_style">
      <el-dialog
        title="查看合同"
        v-model="dialogVisible"
        width="30%"
        :before-close="handleClose"
      >
        <div class="">
          <div
            class="hetong pointer"
            @click="downfirs(openHetongvalue.data.contractPathValue)"
          >
            {{ openHetongvalue.data.contractName }}
          </div>
          <div
            :class="{btn_disabled:!openHetongvalue.data.contractName}"
            class="order_list_buttom pointer"
            @click="order_list_bottom_open(openHetongvalue.data.orderSn,openHetongvalue.data.isConfirmContract)"
          >
            确定
          </div>
        </div>
      </el-dialog>
    </div>
    <!-- 查看附件弹窗 end-->
  </div>
</template>
<script>
import { reactive, getCurrentInstance, ref, onMounted } from "vue";
import {
  ElInput,
  ElMessage,
  ElMessageBox,
  ElTimeline,
  ElTimelineItem,
} from "element-plus";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import SldCommonEmpty from "../../../components/SldCommonEmpty";
import MemberTitle from "../../../components/MemberTitle";
export default {
  name: "order-list",
  components: {
    ElInput,
    ElTimeline,
    ElTimelineItem,
    SldCommonEmpty,
    MemberTitle,
  },
  setup() {
    // const balance = require("../../assets/buy/balance.png");
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const { proxy } = getCurrentInstance();
    const L = proxy.$getCurLanguage();
    const current_state = ref(""); //当前列表订单状态
    const evaluate_state = ref(0); //是否是待评价
    const order_list = reactive({ data: [] });
    const orderSn = ref("");
    const cancel_order = ref(false); //是否显示取消订单弹窗
    const not_select = require("../../../assets/order/not_select.png");
    const selected = require("../../../assets/order/selected.png");
    const invoice_selected = require("../../../assets/buy/invoice_selected.png");
    const cancel_list = reactive({ data: [] });
    const current_reason_id = ref(); //记录当前已选择理由id
    const order_sn = ref(); //记录当前操作的订单号
    const show_logistics = ref(false);
    const logistics_info = reactive({ data: {} });
    const openHetongvalue = reactive({ data: {} }); //合同弹窗显示
    const show_select_address = ref(false); //是否显示选择地址弹窗
    const address_list = reactive({ data: [] }); //地址列表
    const current_address_index = ref(0); //记录当前选择的地址
    const pageData = reactive({
      current: 1,
      pageSize: 10,
      total: 0,
    });
    const dialogVisible = ref(false); //合同弹窗显示

    //去商品详情页
    const goGoodsDetail = (productId) => {
      proxy.$goGoodsDetail(productId);
    };
    //去店铺详情
    const toStore = (storeId) => {
      if (storeId == 0) {
        return;
      }
      proxy.$goStoreDetail(storeId);
    };
    //改变订单状态
    const changeState = (orderState, evaluateState) => {
        console.log(orderState,'orderState')
      if (evaluateState) {
        evaluate_state.value = evaluateState;
      } else {
        evaluate_state.value = "";
      }
      current_state.value = orderState;
      pageData.current = 1;
      router.push({
        path: "/member/order/list",
        query: {
          orderState,
          evaluateState,
        },
      });
      getOrderList();
    };
    //获取订单列表
    const getOrderList = () => {
      var param = {};
      if (evaluate_state.value == 1) {
        param.evaluateState = 1;
      }
      if (current_state.value>=0) {
        param.orderState = current_state.value;
      }
      param.current = pageData.current;
      param.pageSize = pageData.pageSize;
      if (orderSn.value) {
        param.orderSn = orderSn.value;
      }
      proxy
        .$get("/v3/business/front/orderInfo/list", param)
        .then((res) => {
          if (res.state == 200) {
            order_list.data = res.data.list;
            pageData.total = res.data.pagination.total;
          } else {
            ElMessage(res.msg);
          }
        })
        .catch(() => {
          //异常处理
        });
    };
    // 获取订单取消理由列表
    const getCancelList = () => {
      proxy
        .$get("v3/system/front/reason/list", {
          type: 104,
        })
        .then((res) => {
          if (res.state == 200) {
            cancel_list.data = res.data;
          } else {
            ElMessage(res.msg);
          }
        })
        .catch(() => {
          //异常处理
        });
    };
    //选择理由
    const selectReason = (id) => {
      current_reason_id.value = id;
    };
    //显示选择理由弹窗
    const showSelectReasonDialog = (orderSn) => {
      cancel_order.value = true;
      order_sn.value = orderSn;
    };
    //显示查看物流信息
    const lookLogistics = (orderSn) => {
      proxy
        .$get("v3/business/front/logistics/order/getTrace", {
          orderSn,
        })
        .then((res) => {
          if (res.state == 200) {
            console.log(res.data);
            show_logistics.value = true;
            logistics_info.data = res.data;
          } else {
            ElMessage(res.msg);
          }
        })
        .catch(() => {
          //异常处理
        });
    };
    //删除订单
    const delOrder = (orderSn) => {
      ElMessageBox.confirm(L["确认删除该订单?"], L["提示"], {
        confirmButtonText: L["确定"],
        cancelButtonText: L["取消"],
        type: "warning",
      })
        .then(() => {
          proxy
            .$post("v3/business/front/orderOperate/delete", {
              orderSn,
            })
            .then((res) => {
              if (res.state == 200) {
                ElMessage.success(L["删除订单成功"]);
                getOrderList();
              } else {
                ElMessage(res.msg);
              }
            })
            .catch(() => {
              //异常处理
            });
        })
        .catch(() => {});
    };
    //确认取消订单
    const confirmCancel = () => {
      if (!current_reason_id.value) {
        ElMessage.warning(L["请选择取消理由"]);
        return;
      }
      proxy
        .$post("v3/business/front/orderOperate/cancel", {
          parentSn: order_sn.value,
          reasonId: current_reason_id.value,
        })
        .then((res) => {
          if (res.state == 200) {
            ElMessage.success(L["取消订单成功"]);
            cancel_order.value = false;
            getOrderList();
          } else {
            ElMessage(res.msg);
          }
        })
        .catch(() => {
          //异常处理
        });
    };
    //确认收货
    const confirmReceipt = (orderSn) => {
      ElMessageBox.confirm(L["确认收货?"], L["提示"], {
        confirmButtonText: L["确定"],
        cancelButtonText: L["取消"],
        type: "warning",
      })
        .then(() => {
          proxy
            .$post("v3/business/front/orderOperate/receive", {
              orderSn,
            })
            .then((res) => {
              if (res.state == 200) {
                ElMessage.success(L["确认收货成功"]);
                getOrderList();
              } else {
                ElMessage(res.msg);
              }
            })
            .catch(() => {
              //异常处理
            });
        })
        .catch(() => {});
    };
    //立即支付
    const goPay = (orderSn) => {
      router.push({
        path: "/buy/pay",
        query: {
          paySn: orderSn,
          payFrom: 2,
        },
      });
    };
    // 打开合同弹窗
    const openhetong = (item) => {
      dialogVisible.value = true;
      openHetongvalue.data = item;
      console.log(openHetongvalue.data.orderState);
    };
    // 下载合同
    const downfirs = (url) => {
      const aLink = document.createElement("a");
      document.body.appendChild(aLink);
      aLink.style.display = "none";
      aLink.href = url;
      aLink.click();
      document.body.removeChild(aLink);
    };
    // 点击提交合同按钮
    const order_list_bottom_open = (sn,isConfirmContract) => {
      if(!openHetongvalue.data.contractName){
        return;
      }
      if(isConfirmContract==1){
          ElMessage('合同已确认，不能重复确认。');
          dialogVisible.value = false;
          return
      }
      proxy
        .$post("/v3/business/front/orderOperate/confirmContract", {
          orderSn: sn,
        })
        .then((res) => {
          ElMessage(res.msg);
          dialogVisible.value = false;
          getOrderList();
          getCancelList();
          getAddressList();
        });
    };

    //选择地址
    const editAddress = (orderSn) => {
      show_select_address.value = true;
      order_sn.value = orderSn;
    };
    //获取地址列表
    const getAddressList = () => {
      proxy
        .$get("v3/member/front/memberAddress/list")
        .then((res) => {
          if (res.state == 200) {
            address_list.data = res.data.list;
          } else {
            ElMessage(res.msg);
          }
        })
        .catch(() => {
          //异常处理
        });
    };
    //切换地址
    const selectAddress = (index) => {
      current_address_index.value = index;
    };
    //确认修改地址
    const confirmChangeAddress = () => {
      proxy
        .$post("v3/business/front/orderOperate/updateAddress", {
          orderSn: order_sn.value,
          addressId: address_list.data[current_address_index.value].addressId,
        })
        .then((res) => {
          if (res.state == 200) {
            ElMessage.success(L["切换地址成功"]);
            show_select_address.value = false;
          } else {
            ElMessage(res.msg);
          }
        })
        .catch(() => {
          //异常处理
        });
    };
    //评价订单
    const remainEvaluated = (orderSn) => {
      router.push({
        path: "/member/order/evaluate",
        query: {
          orderSn: orderSn,
        },
      });
    };
    //详情
    const goDetail = (orderSn) => {
      router.push({
        path: "/member/order/detail",
        query: {
          orderSn: orderSn,
        },
      });
    };
    const goAriDetail = (id) => {
      router.push({
        path: "/member/order/articleList",
        query: {
          articleId: id,
        },
      });
    };
    //向前翻页
    const handlePrevCilickChange = (e) => {
      pageData.current = e;
      getOrderList();
    };
    //向后翻页
    const handleNextCilickChange = () => {
      pageData.current++;
      getOrderList();
    };
    //页数改变
    const handleCurrentChange = (current) => {
      pageData.current = current;
      getOrderList();
    };
    onMounted(() => {
      if(window.location.href.indexOf('freshPage') != -1){
        let loadList = setTimeout(()=>{
          getOrderList();
          getCancelList();
        },100)
        setTimeout(()=>{
          clearTimeout(loadList);
        },500)
        return;
      }
      if (route.query.orderState) {
        current_state.value = route.query.orderState;
      } else {
        current_state.value = "";
      }
      if (route.query.evaluateState) {
        evaluate_state.value = route.query.evaluateState;
      } else {
        evaluate_state.value = "";
      }
      getOrderList();
      getCancelList();
      getAddressList();
      
    });
    //清空搜索订单
    const clear = () => {
      orderSn.value = "";
      getOrderList();
    };

    const toKefu = (item) => {
      let chatInfo = {
        storeId: item.storeId,
        vendorAvatar: item.storeLogo,
        storeName: item.storeName,
        source: "从订单列表进入",
      };
      store.commit("saveChatBaseInfo", chatInfo);

      let newWin = router.resolve({
        path: "/service",
        query: {
          vid: item.storeId,
        },
      });

      window.open(newWin.href, "_blank");
    };

    router.beforeEach((to, from, next) => {
      if (to.query.orderState) {
        current_state.value = to.query.orderState;
      } else {
        current_state.value = "";
      }
      if (to.query.evaluateState) {
        evaluate_state.value = to.query.evaluateState;
      } else {
        evaluate_state.value = "";
      }
    //   getOrderList();
      next();
    });
    return {
      current_state,
      changeState,
      evaluate_state,
      getOrderList,
      order_list,
      orderSn,
      cancel_order,
      not_select,
      selected,
      cancel_list,
      current_reason_id,
      selectReason,
      confirmCancel,
      showSelectReasonDialog,
      delOrder,
      lookLogistics,
      show_logistics,
      logistics_info,
      confirmReceipt,
      goPay,
      show_select_address,
      address_list,
      invoice_selected,
      current_address_index,
      selectAddress,
      editAddress,
      confirmChangeAddress,
      remainEvaluated,
      goDetail,
      L,
      goGoodsDetail,
      toStore,
      handlePrevCilickChange,
      handleNextCilickChange,
      handleCurrentChange,
      pageData,
      clear,
      toKefu,
      downfirs,
      goAriDetail,
      dialogVisible,
      openHetongvalue,
      openhetong,
      order_list_bottom_open,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "../../../style/newOrderList.scss";

.el-pager li.active {
  color: $colorMain;
  cursor: default;
}

.el-pager li:hover {
  color: $colorMain;
}
</style>

<style>
.order_list_el_diaLog_style .el-dialog__header {
  background-color: red;
}
.order_list_el_diaLog_style .el-dialog__header .el-dialog__title {
  color: #fff;
}
.order_list_el_diaLog_style .el-dialog__close {
  color: #fff;
}
.search_input .el-input__inner{
  border-radius: 0px;
}
.good_info_con .num {
  width: 53px;
}
</style>
